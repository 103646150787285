.circular-image {
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circular-image img {
    max-width: 100%;
    max-height: 100%;
}